
// @ts-nocheck


export const localeCodes =  [
  "en"
]

export const localeLoaders = {
  "en": [{ key: "../languages/en-CA.ts", load: () => import("../languages/en-CA.ts" /* webpackChunkName: "locale__home_node_client__telus_languages_en_CA_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "name": "Canada (en)",
      "code": "en",
      "dir": "ltr",
      "lang": "en",
      "regionCode": "ca-bc",
      "language": "en-CA",
      "files": [
        "/home/node/client/.telus/languages/en-CA.ts"
      ]
    }
  ],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./languages/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://aws-try-disabling-nginx.ca.staging.firstvet.com",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "Canada (en)",
    "code": "en",
    "dir": "ltr",
    "lang": "en",
    "regionCode": "ca-bc",
    "language": "en-CA",
    "files": [
      {
        "path": "/home/node/client/.telus/languages/en-CA.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
